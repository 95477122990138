<template>
  <details ref="target" >
    <slot :close="onClose"/>
  </details>
</template>
<script setup lang="ts">
  const target = ref<HTMLDivElement | null>(null)
  onClickOutside(target, (event) => {
    if(target.value){
      target.value?.removeAttribute('open')
    }
  })
  const onClose=()=>{
    target.value?.removeAttribute('open')
  }
</script>
