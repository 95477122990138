<template>
  <ul
    class="lg:tw-gap-2 xl:tw-gap-6 tw-font-medium tw-menu sm:tw-menu-horizontal tw-items-center tw-flex-nowrap tw-relative tw-z-50 tw-px-0 tw-py-0"
  >
    <li v-for="(item, index) in menuList" :key="index" class="tw-w-full lg:tw-w-auto">
      <NuxtLinkLocale
        for="my-drawer"
        aria-label="close sidebar"
        v-if="!item.child.length"
        :to="item.href"
        @click="item?.onClick"
        class="tw-uppercase tw-text-lg lg:tw-text-base"
        :class="{
          'lg:tw-text-[#b7baca] hover:lg:tw-text-[#fff]': isLight,
        }"
      >
        {{ $t(item.name) }}
      </NuxtLinkLocale>
      <UIDetails v-else v-slot="slotProps">
        <summary
          class="tw-uppercase tw-text-lg lg:tw-text-base"
          :class="{
            'lg:tw-text-[#b7baca] hover:lg:tw-text-[#fff]': isLight,
          }"
          >{{ $t(item.name) }}
        </summary>
        <ul class="tw-flex tw-flex-col tw-gap-y-2 lg:tw-shadow-sm lg:tw-rounded-lg margin-inline-start tw-ms-0 tw-mt-1 before:tw-w-0">
          <li v-for="(subItem, subIndex) in item.child" :key="subIndex">
            <NuxtLinkLocale custom :to="subItem.href" v-slot="scoped">
              <label
                for="my-drawer"
                aria-label="close sidebar"
                class="tw-text-[#111224] tw-bg-[#111224]/[.02] tw-px-5 tw-py-5 tw-rounded tw-cursor-pointer tw-flex"
                :key="subIndex"
                @click="goTo(scoped.navigate, slotProps.close)"
              >
                <div class="tw-flex tw-flex-nowrap tw-justify-between tw-items-center">
                  <div class="tw-flex tw-items-center">
                    <div v-if="subItem.icon" class="tw-mr-2 tw-w-5 tw-h-5">
                      <SvgoCommonIconBankGrey v-if="subItem.icon === 'otc'" />
                      <SvgoCommonIconCryptowallet v-if="subItem.icon === 'pay'" />
                      <SvgoCommonIconTradeWithUs v-if="subItem.icon === 'trade'" />
                      <SvgoCommonLogo v-if="subItem.icon === 'legend'" />
                      <SvgoCommonNews v-if="subItem.icon === 'news'" />
                      <SvgoCommonIconGroup v-if="subItem.icon === 'partner'" />
                      <SvgoCommonIconRing v-if="subItem.icon === 'support'" />
                    </div>
                    <span class="tw-text-base tw-uppercase tw-text-nowrap">{{ $t(subItem.name) }}</span>
                  </div>
                  <SvgoCommonArrow class="tw-w-2 tw-h-4 tw-ml-4" />
                </div>
              </label>
            </NuxtLinkLocale>
          </li>
        </ul>
      </UIDetails>
    </li>
  </ul>
</template>
<script lang="ts" setup>
  const { isZa } = useZA()
  const props = defineProps<{
    isLight?: boolean
    enClosed?: boolean
  }>()
  const zenDesk = useZenDesk()
  const menuList = computed(() => {
    if (unref(isZa)) {
      return [
        {
          name: 'Products',
          child: [
            {
              name: 'Legend OTC',
              icon: 'otc',
              //   desc: 'Cryptocurrency OTC Trading Service trusted by 800+ institutions.',
              href: '/legend-otc',
            },
            {
              name: 'Trade with us',
              icon: 'trade',
              href: '/kyc',
            },
          ],
        },
        {
          name: 'Newsroom',
          href: `/newsroom`,
          child: [],
        },
        {
          name: 'Company',
          child: [
            {
              name: 'About us',
              icon: 'legend',
              //   desc: 'We believe the future of global finance should be open to everyone.',
              href: `/about`,
            },
            {
              name: 'Become our partner',
              icon: 'partner',
              href: `/partner`,
            },
            {
              name: 'Support help center',
              icon: 'support',
              href: 'https://support.legendtrading.com/hc/',
            },
          ],
        },
        {
          name: 'CONTACT',
          child: [],
          onClick() {
            onTouched()
          },
        },
      ]
    }
    //     Product/Legend Pay

    // Showcases

    // Developer
    return [
      {
        name: 'Products',
        child: [
          {
            name: 'Legend OTC',
            icon: 'otc',
            //   desc: 'Cryptocurrency OTC Trading Service trusted by 800+ institutions.',
            href: '/legend-otc',
          },
          {
            name: 'Legend Pay',
            icon: 'pay',
            //   desc: 'Fiat-Crypto On/Off Ramp service covers 100+ countries and 40+ fiat currencies.',
            href: '/legend-pay',
          },
          {
            name: 'Trade with us',
            icon: 'trade',
            href: '/kyc',
          },
        ],
      },
      {
        name: 'Newsroom',
        href: `/newsroom`,
        child: [],
      },
      {
        name: 'Showcases',
        href: `/cases`,
        child: [],
      },
      {
        name: 'Developers',
        href: `/developers`,
        child: [],
      },
      {
        name: 'Company',
        child: [
          {
            name: 'About us',
            icon: 'legend',
            //   desc: 'We believe the future of global finance should be open to everyone.',
            href: `/about`,
          },
          {
            name: 'Become our partner',
            icon: 'partner',
            href: `/partner`,
          },
          {
            name: 'Support help center',
            icon: 'support',
            href: 'https://support.legendtrading.com/hc/',
          },
        ],
      },
      {
        name: 'CONTACT',
        child: [],
        onClick() {
          onTouched()
        },
      },
    ]
  })

  const onTouched = () => {
    zenDesk.onToggle()
  }

  const goTo = (navigate: () => void, close: () => void) => {
    navigate()
    if (props.enClosed) {
      close()
    }
  }
</script>
